.tag-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-bottom: -5px;

  &.disabled {
    display: none;
  }

  li {
    margin-right: 5px;
    margin-bottom: 5px;
    flex-shrink: 0;
  }

  &.hovered {
    .tag {
      border: var(--border-width) solid transparent;
      transition: 0.3s ease-in-out all;

      &:hover {
        border-color: var(--color-primary);
      }
    }
  }
}

.tag {
  border-radius: 7px;
  background-color: var(--color-lightgrey-border);
  padding: 8px 10px;
  cursor: pointer;

  transition: ease-in-out 0.3s;

  &__name {
    font-size: 12px;
    color: var(--color-darkgrey);
    text-transform: uppercase;
    display: flex;
    align-items: center;

    span {
      background-color: var(--color-darkgrey);
      border-radius: 50%;
      color: var(--color-white);
      font-size: 8px;
      padding: 3px;
      margin-left: 10px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;

      &:hover {
        background-color: var(--color-primary);
      }
    }
  }

  @mixin tab {
    &__name {
      font-size: 10px;
    }
  }

  @mixin media 1200 {
    padding: 5px 8px;
    border-radius: 5px;
  }
}
