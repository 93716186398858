:root {
  /* DEFAULT VARIABLES */
  --border-width: 2px;
  --header-height: 62px;
  --sidebar-width: 300px;
  --right-sidebar-width: 300px;

  /* MAIN COLORS THAT DON'T CHANGE WITH THEME */
  --color-green: #5bdb1f;
  --color-green-swamp: #1ca481;

  /* MAIN COLORS WHITE THEME */
  --logo-wt-main-color: #2e344a;
  --logo-wt-second-color: #f2f0f0;

  --color-wt-primary: #0072ff;

  --color-wt-bg: #f9f9fa;
  --color-wt-theme-toggle: #334556;

  --color-wt-black: #000;
  --color-wt-main-black: #334556;
  --color-wt-text-black: #262d41;

  --color-wt-darkblue: #142238;

  --color-wt-darkgrey: #626a7f;
  --color-wt-darkgrey-text: #9ca8ba;
  --color-wt-grey: #afb2c3;
  --color-wt-lightgrey: #f1f8fe;
  --color-wt-lightgrey-border: #f3f3f7;
  --color-wt-lightgrey-bg: #fcfdff;

  --color-wt-main-content: #f9f9fa;
  --color-wt-white: #fff;

  --color-wt-inline-code: crimson;
  --color-wt-bg-inline-code: rgba(222, 222, 222, 0.5);

  /* MAIN COLORS DARK THEME */
  --logo-dt-main-color: #ecf3ff;
  --logo-dt-second-color: #1a1c20;

  --color-dt-primary: #fdcc05;

  --color-dt-bg: #0d1116;
  --color-dt-theme-toggle: #a1acca;

  --color-dt-black: #fff;
  --color-dt-main-black: #fff;
  --color-dt-text-black: #cfd2d7;

  --color-dt-darkblue: #c4c4c4;

  --color-dt-darkgrey: #97a2ae;
  --color-dt-darkgrey-text: #b2b7cb;
  --color-dt-grey: #afb2c3;
  --color-dt-lightgrey: #2d2f33;
  --color-dt-lightgrey-border: #3b3c3f;
  --color-dt-lightgrey-bg: #1a1c20;

  --color-dt-main-content: #0d1116;
  --color-dt-white: #1a1c20;

  --color-dt-inline-code: #13c330;
  --color-dt-bg-inline-code: rgba(82, 82, 82, 0.5);

  /* CATEGORY COLORS */
  --color-1: #6cc606;
  --color-wt-1-bg: #dffee2;
  --color-dt-1-bg: #002f05;

  --color-2: #fe5a36;
  --color-wt-2-bg: #ffddd6;
  --color-dt-2-bg: #370a00;

  --color-3: #308cff;
  --color-wt-3-bg: #cfe2ff;
  --color-dt-3-bg: #001940;

  --color-4: #7b61ff;
  --color-wt-4-bg: #f3e4ff;
  --color-dt-4-bg: #260044;

  --color-5: #f34b8c;
  --color-wt-5-bg: #ffddea;
  --color-dt-5-bg: #410019;

  --color-6: #fdae14;
  --color-wt-6-bg: #fff2d9;
  --color-dt-6-bg: #3e2b0b;

  /* CODE COLOR */
  --color-code: #282c34;

  /* FONTS */
  --font-main: "Inter", sans-serif;
  --font-code: "Source Code Pro", monospace;

  /* Editor */
  --color-text-blue: #5c5c85;
}

:root {
  --color-logo-main: var(--logo-wt-main-color);
  --color-logo-second: var(--logo-wt-second-color);

  --color-primary: var(--color-wt-primary);

  --color-bg: var(--color-wt-bg);
  --color-theme-toggle: var(--color-wt-theme-toggle);

  --color-black: var(--color-wt-black);
  --color-main-black: var(--color-wt-main-black);
  --color-text-black: var(--color-wt-text-black);

  --color-darkblue: var(--color-wt-darkblue);

  --color-darkgrey: var(--color-wt-darkgrey);
  --color-darkgrey-text: var(--color-wt-darkgrey-text);
  --color-grey: var(--color-wt-grey);
  --color-lightgrey: var(--color-wt-lightgrey);
  --color-lightgrey-border: var(--color-wt-lightgrey-border);
  --color-lightgrey-bg: var(--color-wt-lightgrey-bg);

  --color-main-content: var(--color-wt-main-content);
  --color-white: var(--color-wt-white);

  --color-inline-code: var(--color-wt-inline-code);
  --color-bg-inline-code: var(--color-wt-bg-inline-code);

  --color-1-bg: var(--color-wt-1-bg);
  --color-2-bg: var(--color-wt-2-bg);
  --color-3-bg: var(--color-wt-3-bg);
  --color-4-bg: var(--color-wt-4-bg);
  --color-5-bg: var(--color-wt-5-bg);
  --color-6-bg: var(--color-wt-6-bg);
}

.dark {
  --color-logo-main: var(--logo-dt-main-color);
  --color-logo-second: var(--logo-dt-second-color);

  --color-primary: var(--color-dt-primary);

  --color-bg: var(--color-dt-bg);
  --color-theme-toggle: var(--color-dt-theme-toggle);

  --color-black: var(--color-dt-black);
  --color-main-black: var(--color-dt-main-black);
  --color-text-black: var(--color-dt-text-black);

  --color-darkblue: var(--color-dt-darkblue);

  --color-darkgrey: var(--color-dt-darkgrey);
  --color-darkgrey-text: var(--color-dt-darkgrey-text);
  --color-grey: var(--color-dt-grey);
  --color-lightgrey: var(--color-dt-lightgrey);
  --color-lightgrey-border: var(--color-dt-lightgrey-border);
  --color-lightgrey-bg: var(--color-dt-lightgrey-bg);

  --color-main-content: var(--color-dt-main-content);
  --color-white: var(--color-dt-white);

  --color-inline-code: var(--color-dt-inline-code);
  --color-bg-inline-code: var(--color-dt-bg-inline-code);

  --color-1-bg: var(--color-dt-1-bg);
  --color-2-bg: var(--color-dt-2-bg);
  --color-3-bg: var(--color-dt-3-bg);
  --color-4-bg: var(--color-dt-4-bg);
  --color-5-bg: var(--color-dt-5-bg);
  --color-6-bg: var(--color-dt-6-bg);
}

@keyframes slideDown {
  0% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(50px);
  }

  65% {
    transform: translate(50px, -50px);
  }

  70% {
    transform: translate(0, -50px);
  }

  100% {
    transform: translate(0px);
  }
}

@keyframes gradient {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
