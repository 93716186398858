.logo-icon {
  width: 32px;
  height: 32px;
  font-size: 31.99999px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "\e930";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.header {
  padding: 10px 20px 10px 25px;
  height: var(--header-height);

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--color-white);
  border-bottom: var(--border-width) solid var(--color-lightgrey-border);

  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  transition-property: background-color, border-bottom-color;

  position: fixed;
  width: calc(100% - var(--sidebar-width));
  z-index: 20;
  right: 0;

  .theme-btn {
    margin-left: auto;

    & + .profile-block {
      margin-left: 15px;
    }
  }

  @mixin mob-xl {
    .menu-btn {
      margin-right: 15px;
    }

    .theme-btn {
      & + .profile-block {
        margin-left: 10px;
        font-size: 14px;
      }
    }

    .profile-block {
      margin-left: auto;
      justify-content: flex-end;
      grid-column-gap: 10px;

      &__descr {
        display: none;
      }
    }
  }
}

.profile-block {
  display: flex;
  align-items: center;
  grid-column-gap: 15px;

  &__info {
    display: flex;
    align-items: center;
    grid-column-gap: 15px;
    cursor: pointer;

    &:hover {
      .profile-block__image {
        border-color: var(--color-primary);
      }
    }
  }

  &__image {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
    border: solid var(--border-width) transparent;
    transition: ease-in-out 0.3s;

    @mixin media 768 {
      width: 36px;
      height: 36px;
      flex: 0 0 36px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__descr {
    line-height: 120%;

    h3 {
      font-size: 14px;
      text-transform: capitalize;
      line-height: 1.2;
    }

    p {
      font-size: 12px;
    }

    @mixin media 768 {
      h3 {
        font-size: 12px;
      }
    }

    @mixin mob-xl {
      display: none;
    }
  }
}
