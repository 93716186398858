.post-sec {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 820px;
  margin: 0 auto;

  &__category-name {
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-start;
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--color-darkgrey);

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 7px;
      background-color: #383838;
      transition: 0.3s ease-in-out all;
    }

    &:hover {
      &:before {
        transform: scale(1.1);
      }
    }

    &.color-1 {
      &:before {
        background-color: var(--color-1);
      }
    }

    &.color-2 {
      &:before {
        background-color: var(--color-2);
      }
    }

    &.color-3 {
      &:before {
        background-color: var(--color-3);
      }
    }

    &.color-4 {
      &:before {
        background-color: var(--color-4);
      }
    }

    &.color-5 {
      &:before {
        background-color: var(--color-5);
      }
    }

    &.color-6 {
      &:before {
        background-color: var(--color-6);
      }
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;

    .tag-list {
      margin-top: 15px;
    }
  }

  &__title {
    display: grid;
    grid-template-columns: auto 30px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: var(--border-width) solid var(--color-lightgrey-border);
    transition: 0.3s ease-in-out all;

    h1 {
      font-size: 24px;
      font-weight: 700;
    }

    .btn {
      margin-left: auto;
      flex: 0 0 30px;
    }

    span {
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
      }

      &.active {
        transition: ease-in-out 0.3s;
        color: var(--color-primary);
        text-decoration: underline;
      }
    }
  }

  &__posts,
  &__sections {
    display: none;
    &.active {
      display: block;
    }
  }

  &__descr {
    margin-top: 20px;
    max-width: 700px;
    width: calc(100% - 60px);
    color: var(--color-text-black);

    * + img,
    * + .file-list,
    * + .file * + hr {
      margin-top: 20px;
    }

    hr {
      border-color: var(--color-grey);
    }

    h1,
    h2,
    h3 {
      line-height: 130%;

      & + *:not(img, .file-list) {
        margin-top: 15px;
      }
    }

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    u {
      i {
        text-decoration: underline;
      }
    }

    strong,
    b {
      font-weight: 700;
    }

    ol,
    ul {
      list-style-position: outside;
      margin-left: 20px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    img {
      border-radius: 7px;
      max-height: 500px;
    }

    .file-list,
    .file,
    img,
    hr {
      & + * {
        margin-top: 20px;
      }
    }

    a {
      display: inline;
      color: var(--color-primary);
      transition: 0.3s ease-in-out all;

      &:hover {
        text-decoration: underline;
      }
    }

    p,
    a,
    .inline-code,
    .cdx-code,
    .quote,
    ul,
    ol {
      font-size: 15px;
      line-height: 150%;

      & + *:not(img) {
        margin-top: 15px;
      }
    }

    .quote {
      blockquote {
        margin-bottom: 5px;
        width: max-content;
        max-width: 100%;

        &::before {
          content: '"';
          font-size: 20px;
        }

        &::after {
          content: '"';
          font-size: 20px;
        }
      }
    }

    .cdx-code {
      font-family: var(--font-code);
      font-style: normal;
      font-size: 12px;
      background-color: var(--color-white);
      border-radius: 4px;
      position: relative;

      border: var(--border-width) solid var(--color-lightgrey-border);
      word-break: break-all;
      color: var(--color-inline-code);

      transition: ease-in-out 0.3s all;

      code {
        padding: 15px;
        width: 100%;
        display: block;
        overflow: auto;

        @mixin scrollbar;
      }

      .btn-copy {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 20px;
        color: var(--color-text-black);
        transition: 0.3s ease-in-out all;
        background-color: transparent;
        opacity: 0;
        visibility: hidden;
        margin: 0;
        cursor: pointer;

        &:hover {
          color: var(--color-primary);
        }
      }

      &:hover {
        .btn-copy {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .inline-code {
      font-family: var(--font-code);
      font-style: normal;
      padding: 0 5px;
      color: var(--color-inline-code);
      background-color: var(--color-bg-inline-code);
      border-radius: 4px;
      transition: ease-in-out color, background-color 0.3s;
    }

    .cdx-marker {
      color: var(--color-green);
    }

    table {
      width: 100%;
      margin-bottom: 20px;

      tr {
        transition: ease-in-out 0.25s;
      }

      tr:nth-child(odd) {
        background: var(--color-lightgrey);
      }

      tr:nth-child(even) {
        background: var(--color-lightgrey-border);
      }

      td {
        padding: 10px 5px;
      }
    }

    /*blockquote {
        font-family: var(--font-code);
        font-style: normal;
        font-size: 12px;
        padding: 15px;
        background-color: var(--color-white);
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        border: var(--border-width) solid var(--color-lightgrey-border);
        word-break: break-all;

        //Copy Icon
        &:before {
            content: "\e914";
            font-family: 'icomoon' !important;
            position: absolute;
            top: 12px;
            right: 12px;
            font-size: 20px;
            color: var(--color-text-black);
            transition: 0.3s ease-in-out all;
            opacity: 0;
            visibility: hidden;
        }

        .btn-copy {
            position: absolute;
            top: 12px;
            right: 12px;
            font-size: 20px;
            color: var(--color-text-black);
            transition: 0.3s ease-in-out all;
            background-color: transparent;
            opacity: 0;
            visibility: hidden;
            margin: 0;
            cursor: pointer;

            &:hover {
                color: var(--color-blue);
            }
        }

        &:hover {
            &:before {
                opacity: 1;
                visibility: visible;
            }

            .btn-copy {
                opacity: 1;
                visibility: visible;
            }
        }

        i {
            font-style: inherit;
            color: var(--color-green);
            display: inline-block;
        }

        p {
            font-size: inherit;

            & + * {
                margin-top: 5px;
            }
        }
    }*/

    .btn {
      color: var(--color-main-black);
      display: flex;
    }
  }

  &__columns-content {
    display: flex;
    align-items: flex-start;

    .media-gallery {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .tabs {
    margin-top: 30px;

    & + .descr {
      margin-top: 20px;
    }
  }

  .sticky-author {
    position: sticky;
    top: calc(20px + var(--header-height));
    z-index: 10;

    &__top {
      margin-top: 20px;
    }

    .btn {
      &.active {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }

      &:hover {
        color: var(--color-primary);
      }

      &.rectangle {
        &.active {
          border-color: transparent;

          & + .author__rate {
            color: var(--color-primary);
          }
        }

        &:hover {
          border-color: transparent;

          & + .author__rate {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  @mixin tab {
    max-width: 100%;

    &__title {
      h1 {
        font-size: 20px;
      }
    }

    &__descr {
      max-width: 700px;

      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 18px;
      }

      h3 {
        font-size: 16px;
      }

      p,
      a,
      .inline-code,
      .cdx-code,
      .quote,
      ul,
      ol {
        font-size: 14px;
      }

      pre {
        padding: 10px;
      }
    }

    &__columns-content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
}

.accordion {
  &__head {
    margin: 0 0 -1px;
    padding: 0;
    list-style: none;
  }

  &__head li {
    display: inline-block;
    z-index: 100;
    background: #fff;
    border: 1px solid #ddd;
    font-weight: bold;
  }

  &__head li.is-open {
    border-bottom-color: transparent;
  }

  &__head a {
    display: block;
    padding: 0 10px;
    color: inherit;
    font-size: 16px;
    line-height: 2.5;
    text-decoration: none;
    white-space: nowrap;
  }

  &__item.beefup {
    display: none;
    margin: 0;
    border-radius: 0;
  }

  &__item.beefup.is-open {
    display: block;
  }

  &__item .beefup__head {
    display: none;
  }

  /* &__item {
        &-trigger {
            button {
                background: transparent;
                width: 100%;
                border: none;
            }
        }
        &-content {
            width: 100%;
        }
    } */
}
