:root {
  @mixin desk-sm {
    --right-sidebar-width: 260px;
  }

  @mixin media 1101 {
    --right-sidebar-width: 215px;
  }

  @mixin tab {
    --right-sidebar-width: 100%;
  }
}

.right-sidebar {
  width: var(--right-sidebar-width);
  flex: 0 0 var(--right-sidebar-width);
  padding: 20px 20px 20px 0;

  &__info {
    h2 {
      font-size: 20px;
      margin-bottom: 20px;
      color: var(--color-main-black);
    }

    & + .right-sidebar__info {
      margin-top: 12px;
      padding-top: 20px;
      border-top: var(--border-width) solid var(--color-grey);
    }
  }

  @mixin desk-sm {
    padding: 20px 15px 20px 0;
  }

  @mixin media 1101 {
    padding-right: 10px;
    &__info {
      h2 {
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }

  @mixin tab {
    padding: 15px;

    &__info {
      h2 {
        font-size: 18px;
      }
    }
  }

  @mixin tab-sm {
    grid-template-columns: 1fr;
  }
}

.author-post {
  transition: 0.3s ease-in-out all;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  /*&:before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: var(--color-primary);
        display: none;
    }*/

  &:hover {
    background-color: var(--color-white);
    box-shadow: 0px 2px 20px rgba(var(--color-white), 0.04);
  }

  .author {
    padding: 8px 15px 8px 8px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in-out all;

    &__post-info {
      margin-left: 15px;

      @mixin media 1101 {
        margin-left: 10px;
      }
    }

    &:hover {
      .author__image-colored {
        padding: 0;

        img {
          transform: scale(1.15);
        }
      }
    }

    @mixin media 451 {
      padding: 5px 10px 5px 5px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &.checked {
    &:before {
      display: block;
    }
  }
}
