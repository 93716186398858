.preload * {
  &,
  &::after,
  &::before {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
}

.container {
  contain: layout inline-size;
}

body {
  position: relative;
  margin: auto;
  background: var(--color-bg);
  font-family: var(--font-main);
  overflow-x: hidden;

  &.disabled {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
  }

  @mixin scrollbar;
}

a {
  color: var(--color-text-black);
}

html {
  font-size: 16px;
}

.image-block {
  @mixin aspect-ratio 16, 9;
  -webkit-border-radius: 5vw;
  -moz-border-radius: 5vw;
  border-radius: 5vw;
}

.simplebar-placeholder {
  width: auto !important;
}

/* MAIN START */
.main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
  height: 100%;
}

.main-content {
  flex: 1;
  background-color: var(--color-main-content);
  transition: 0.3s ease-in-out all;

  &__inner {
    display: flex;

    height: max-content;
    width: calc(100vw - var(--sidebar-width));
    margin-top: 62px;
    margin-left: auto;

    @mixin media 660 {
      margin-right: auto;
    }

    .simplebar-track {
      &.simplebar-vertical {
        right: -2px;
      }
    }

    .simplebar-content {
      display: flex;
    }
  }

  &__descr {
    flex: 1;
    padding: 20px;
    height: max-content;
    width: calc(100% - var(--right-sidebar-width));

    @mixin media 1101 {
      padding: 20px 15px;
    }

    @mixin tab {
      width: 100%;
    }
  }

  @mixin tab {
    &__descr {
      padding: 15px;
    }
  }

  @mixin mob-xl {
    transition: 0.3s ease-in-out all;

    &:before {
      content: "";
      position: fixed;
      z-index: 15;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(11, 11, 11, 0.4);
      transition: 0.3s ease-in-out all;
      opacity: 0;
      visibility: hidden;
    }

    &.active {
      position: relative;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @mixin mob-sm {
    &__descr {
      padding: 10px;
    }
  }
}

.main-page {
  max-width: 800px;
  margin: 0 auto;
}
/* MAIN END */

/* SIMPLEBAR */
[data-simplebar] {
  &:hover {
    .simplebar-scrollbar {
      &::before {
        opacity: 1;
      }
    }
  }
}

.simplebar-scrollbar {
  &::before {
    transition: ease-in-out 0.3s;
    background: var(--color-lightgrey-border);
  }

  &.simplebar-visible {
    &::before {
      opacity: 1;
    }
  }
}

.simplebar-track {
  &.simplebar-vertical {
    width: 9px;
  }
}
/* SIMPLEBAR */

.files-descr {
  margin-top: 15px;
}

.file-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;

  @mixin mob {
    grid-template-columns: 1fr;
  }
}

.file {
  padding: 15px;
  background-color: var(--color-white);
  box-shadow: 0px 2px 10px rgba(var(--color-white), 0.1);
  border: var(--border-width) solid var(--color-lightgrey-border);
  border-radius: 7px;
  width: clamp(275px, 47%, 350px);
  display: inline-flex;
  align-items: center;
  grid-column-gap: 15px;
  margin-right: 10px;

  & + .file {
    margin-top: 10px;
  }

  &__icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 5px;

    &.icon-pdf {
      background-color: var(--color-green-swamp);
    }

    &.icon-docx,
    &.icon-folder {
      background-color: var(--color-primary);
    }
  }

  &__info {
    font-size: 12px;
    flex: 1;

    h4 {
      font-weight: 500;
      font-size: inherit;
      color: var(--color-black);

      transition: ease-in-out 0.3s;
    }

    p {
      font-size: inherit;
      color: var(--color-darkgrey);

      transition: ease-in-out 0.3s;
    }
  }

  .btn {
    &:hover {
      border-color: var(--color-primary);
    }
  }

  &.image {
    overflow: hidden;
    position: relative;
    align-items: flex-end;
    padding: 10px;

    &:before {
      content: "";
      background: linear-gradient(180deg, rgba(23, 32, 47, 0) 0%, #050112 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 50%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .file__info,
    .btn {
      position: relative;
      z-index: 1;
    }

    .file__info {
      color: var(--color-white);

      h4 {
        font-weight: 600;
      }

      p {
        color: var(--color-white);
      }
    }
  }

  @mixin tab {
    &__info {
      p {
        font-size: inherit;
      }
    }
  }

  @mixin mob {
    width: 100%;
  }
}

.author {
  &__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: solid var(--border-width) transparent;
    transition: ease-in-out 0.3s;

    &:hover {
      border-color: var(--color-primary);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image-colored {
    height: 48px;
    width: 48px;
    flex: 0 0 48px;
    padding: 5px;
    background-color: var(--color-grey);
    border: var(--border-width) solid #383838;
    border-radius: 50%;
    transition: ease-in-out 0.3s;
    overflow: hidden;

    &:hover {
      padding: 0;

      img {
        transform: scale(1.15);
      }
    }

    img {
      object-fit: cover;
      border-radius: 50%;
      transition: ease-in-out 0.3s;
    }

    &.color-1 {
      border-color: var(--color-1);
      background-color: var(--color-1-bg);
    }

    &.color-2 {
      border-color: var(--color-2);
      background-color: var(--color-2-bg);
    }

    &.color-3 {
      border-color: var(--color-3);
      background-color: var(--color-3-bg);
    }

    &.color-4 {
      border-color: var(--color-4);
      background-color: var(--color-4-bg);
    }

    &.color-5 {
      border-color: var(--color-5);
      background-color: var(--color-5-bg);
    }

    &.color-6 {
      border-color: var(--color-6);
      background-color: var(--color-6-bg);
    }

    @mixin media 1101 {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      padding: 4px;
    }

    @mixin media 421 {
      height: 48px;
      width: 48px;
      flex: 0 0 48px;
      padding: 5px;
    }
  }

  &__post-info {
    h3 {
      line-height: 130%;
      font-size: 12px;
      color: var(--color-text-black);
      margin-bottom: 2px;

      @include max-line-leng(2);
    }

    p {
      font-size: 11px;
      color: var(--color-darkgrey);
    }

    @mixin media 1101 {
      h3 {
        font-size: 11px;
      }
      p {
        font-size: 9.5px;
      }
    }

    @mixin media 421 {
      h3 {
        font-size: 12px;
      }
      p {
        font-size: 11px;
      }
    }
  }

  &__top {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 15px;
  }

  &__bottom {
    border-top: var(--border-width) solid var(--color-grey);
    padding-top: 15px;
    transition: 0.3s ease-in-out all;

    .btn {
      &.rectangle {
        background-color: transparent;

        &:hover {
          background-color: var(--color-lightgrey-border);
          border-color: transparent;
        }
      }
    }
  }

  &__rate {
    display: block;
    text-align: center;
    margin: 5px 0;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s ease-in-out all;
    color: var(--color-black);
  }

  &__comments {
    text-align: center;
    background-color: var(--color-lightgrey-border);
    color: var(--color-text-black);
    padding: 3px 10px;
    max-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px 5px 5px 0;

    transition: 0.3s ease-in-out all;

    &:before {
      content: "";
      background-color: var(--color-lightgrey-border);
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      width: 7px;
      height: 8px;
      position: absolute;
      top: 100%;
      left: 0;

      transition: 0.3s ease-in-out all;
    }
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  .post-card {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @mixin tab-sm {
    grid-template-columns: 1fr;
  }
}

.page404 {
  padding: 50px 15px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: min(50vw, 725px);
    height: min(50vh, 435px);
    max-height: 435px;
    object-fit: contain;

    @media only screen and (max-height: 670px) {
      height: 40vh;
    }

    @media only screen and (max-width: 1025px) and (max-height: 670px) {
      height: auto;
    }
  }

  h1 {
    font-size: 64px;
    font-weight: 700;
    margin-top: 35px;
    color: var(--color-text-black);
  }

  p {
    font-size: 20px;
    margin-top: 20px;
    color: var(--color-text-black);
    text-align: center;
    max-width: max(460px, 30%);
  }

  .btn-404 {
    margin-top: 40px;
    font-size: 18px;
    color: var(--color-white);
    padding: 15px 20px;
    background-color: var(--color-primary);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    min-width: 225px;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: skew(45deg);
    }

    &:hover {
      &:after {
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0.7) 100%
        );
        animation: gradient 0.3s linear forwards;
      }
    }
  }

  @mixin tab-sm {
    padding: 30px 15px;

    img {
      width: 380px;
    }

    h1 {
      font-size: 44px;
    }

    p {
      font-size: 16px;
      max-width: max(300px, 30%);
    }
  }
}

@mixin desk-sm {
  :root {
    --sidebar-width: 260px;
  }
}

@mixin tab-md {
  :root {
    --sidebar-width: 240px;
  }
}

@mixin tab-sm {
  :root {
    --border-width: 1.5px;
    --sidebar-width: 220px;
  }
}

@mixin mob-xl {
  :root {
    --sidebar-width: 0px;
  }
}

.user-page {
  --user-bar-width: 298px;
}

.user-bar-wrap {
  width: var(--user-bar-width);
  height: calc(100% - 100px);

  padding: 10px;
  border-radius: 7px;

  color: var(--color-text-black);
  background-color: var(--color-white);

  transition: ease-in-out 0.3s;
}

.user-bar {
  width: 100%;
  height: 100%;
}

.user-bar-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-grey);
}

.user-bar-img {
  width: 96px;
  height: 96px;
  object-fit: cover;

  margin-bottom: 20px;

  border-radius: 50%;
}

.user-bar-name {
  margin-bottom: 2px;

  font-size: 18px;
  font-weight: 600;
}

.user-bar-position {
  color: var(--color-darkgrey-text);
}

.user-bar-block-title {
  margin-bottom: 12px;

  font-size: 15px;
  font-weight: 600;
}

.user-bar-statistics {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-grey);
}

.user-bar-statistic-list {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px 20px;
}

.user-bar-statistic-data {
  font-weight: 500;
}

.user-page-post-list {
  margin-left: calc(var(--user-bar-width) + 10px);
}

#form_login {
  input {
    @mixin transparent-bg-input var(--color-black);
  }
}
