.sidebar {
  width: var(--sidebar-width);
  flex: 0 0 var(--sidebar-width);
  background-color: var(--color-white);

  padding-top: 10px;
  border-right: var(--border-width) solid var(--color-lightgrey-border);

  transition: 0.3s ease-in-out all;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  z-index: 999;

  &__logo {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-left: 20px;

    @mixin media 1101 {
      margin-left: 15px;
    }

    @mixin media 768 {
      margin-left: 10px;
    }

    a {
      font-size: 16px;
      font-weight: 600;
      color: var(--color-darkblue);
      display: flex;
      align-items: center;
    }

    img,
    .logo-icon {
      margin-right: 10px;
    }

    svg > path {
      transition: ease-in-out 0.3s;
      fill: var(--color-logo-main);
    }
    svg > path.another-fill {
      fill: var(--color-logo-second);
    }
  }

  &__content {
    .simplebar-track {
      .simplebar-scrollbar {
        &::before {
          transition: ease-in-out 0.3s;
        }

        &:hover,
        &:active {
          &::before {
            background: #747474;
          }
        }
      }

      &.simplebar-vertical {
        pointer-events: all;
      }
    }
  }

  &__form {
    margin: 0 20px 15px;

    @mixin media 1101 {
      margin: 0 15px 15px;
    }

    @mixin media 768 {
      margin: 0 10px 15px;
    }
  }

  &__search {
    position: relative;
    color: var(--color-darkgrey-text);
    width: 100%;
    display: flex;
    align-items: center;

    span {
      font-size: 20px;
      position: absolute;
      left: 10px;
      cursor: pointer;

      @mixin media 768 {
        font-size: 16px;
      }
    }

    input {
      border: var(--border-width) solid var(--color-lightgrey-border);
      border-radius: 7px;
      padding: 10px 10px 10px 40px;
      font-size: 15px;
      font-family: var(--font-main);
      color: var(--color-darkgrey-text);
      width: 100%;
      background-color: inherit;
      transition: 0.3s ease-in-out all;

      &::placeholder {
        color: var(--color-darkgrey-text);
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 40px var(--color-lightgrey-bg) inset !important;
        -webkit-text-fill-color: var(--color-darkgrey-text) !important;
      }

      &:focus {
        border-color: var(--color-primary);
      }

      @mixin media 768 {
        padding: 8px 5px 8px 32px;
      }
    }
  }

  &__categories {
    margin: 0 20px;

    h2 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 7px;
    }

    @mixin media 1101 {
      margin: 0 15px;
    }

    @mixin media 768 {
      margin: 0 10px 15px;

      h2 {
        font-size: 16px;
      }
    }

    @mixin media 551 {
      h2 {
        font-size: 18px;
      }
    }
  }

  @mixin mob-xl {
    position: fixed;
    z-index: 30;
    left: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    flex: 0 0 300px;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out all;

    .menu-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;

      span {
        &:not(:first-child) {
          display: none;
        }

        &:first-child {
          top: auto;
          left: auto;
          width: auto;
          height: auto;
          background-color: transparent;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            content: "\e907";
            font-family: "icomoon" !important;
          }
        }
      }
    }

    &.active {
      transform: translateX(0);
    }
  }

  @mixin mob-sm {
    width: 260px;
    flex: 0 0 260px;
  }
}

.suggests_result {
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  background-color: var(--color-lightgrey-border);
  width: 100%;
  min-height: 0;
  max-height: 300px;
  /*margin-top: -10px;*/
  box-sizing: border-box;
  overflow-y: auto;
  border: var(--border-width) solid #e1272c;
  border-radius: 5px;
  z-index: 99999;

  padding: 10px;
  border-color: #383838;
  top: 100%;

  &:empty {
    display: none;
  }

  .result-item {
    font-weight: 600;
    font-size: 15px;
    color: var(color-black);
    cursor: pointer;
    padding: 5px;
    transition: 0.3s ease-in-out all;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &:hover {
      background-color: var(--color-lightgrey-border);
    }
  }
}

.category {
  &__name {
    padding: 10px 15px;
    border-radius: 7px;
    transition: 0.3s ease-in-out all;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    &:last-child {
      &::before {
        opacity: 0;
        visibility: hidden;
      }
    }

    &:not(.category__name.post):before {
      content: "\e904";
      font-family: "icomoon" !important;
      font-size: 5px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-darkgrey-text);
      transition: 0.3s ease-in-out all;
      margin-right: 5px;
    }

    span,
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      transition: 0.3s ease-in-out all;
    }

    span {
      color: var(--color-grey);
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      object-fit: contain;
    }

    &:hover {
      background-color: var(--color-lightgrey);

      span {
        color: var(--color-darkgrey);
      }

      &:not(.category__name.post):before {
        color: var(--color-darkgrey-text);
      }
    }

    &.post {
      span {
        color: var(--color-darkgrey);
        font-size: 6px;
      }
    }

    & + ul {
      margin-top: 2px;
      padding-left: 22px;
    }

    @mixin media 551 {
      font-size: 16px;
      padding: 12px 15px;
    }
  }

  ul {
    transition: 0.3s ease-in-out all;
  }

  li {
    transition: 0.3s ease-in-out all;

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &.selected {
      & > .category__name {
        background-color: var(--color-lightgrey);

        span {
          color: var(--color-darkgrey);
        }
      }
    }

    &.empty {
      & > .category__name {
        &:before {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  & > li {
    &.open > .category__name {
      &:not(.category__name.post):before {
        color: var(--color-darkgrey-text);
        transform: rotate(180deg);
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  &.search-category {
    margin: 10px 0 0;
    padding: 0 0 10px;

    max-height: 236px;
    border-bottom: var(--border-width) solid var(--color-lightgrey-border);

    overflow-y: auto;
    @mixin scrollbar;

    &.disabled {
      display: none;
    }

    h2 {
      text-align: center;
      font-weight: 500;
      color: var(--color-darkgrey);
      font-size: 18px;
    }

    .category__name {
      &:not(.category__name.post):before {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }
  }

  &.module-category {
    .category__name {
      padding-left: 36px;

      &::before {
        content: none;
      }

      @mixin media 1101 {
        padding-left: 32px;
      }

      @mixin media 1024 {
        padding: 10px;
        font-size: 14px;
      }

      @mixin media 551 {
        padding: 14px 32px;
        font-size: 16px;
      }
    }
  }
}

.subcategory {
  &:not(.two) {
    & > li {
      &.open {
        & > .category__name:not(.category__name.post):before {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &.two {
    & > li {
      &.open {
        & > .category__name:not(.category__name.post):before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

[data-accordion],
[data-control] {
  padding-right: 0 !important;
}

[data-content],
[data-control] {
  border: none !important;

  & > * {
    padding: 0 !important;
    border: none !important;
  }
}
