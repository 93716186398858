/* Inter fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Code fonts */
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600;700;800;900&display=swap");

h1,
h2,
h3 {
  font-weight: 600;
  color: var(--color-black);
  transition: 0.3s ease-in-out color;
}

p {
  color: var(--color-black);
  transition: 0.3s ease-in-out color;
}
