.new-page-btn {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    span {
      &:after {
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0.7) 100%
        );
        animation: gradient 0.3s linear forwards;
      }
    }
  }

  span {
    margin-right: 10px;
    font-size: 11px;
    color: var(--color-white);
    padding: 9px;
    background-color: var(--color-primary);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.3s ease-in-out all;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: skew(45deg);
    }
  }

  p {
    font-size: 15px;
  }

  @mixin tab {
    p {
      font-size: 14px;
    }
  }

  @mixin mob-xl {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    font-size: 12px;

    span {
      margin-right: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    p {
      display: none;
    }
  }
}

.btn {
  position: relative;
  background: transparent;

  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  font-size: 15px;
  color: var(--color-main-black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: var(--border-width) solid transparent;
  transition: 0.3s ease-in-out all;

  @mixin tab-sm {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    font-size: 14px;
  }

  &:hover {
    background-color: var(--color-lightgrey-border);
    border-color: var(--color-primary);
    color: var(--color-primary);
  }

  &.with-bg {
    background-color: var(--color-lightgrey-border);
  }

  &.square {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;

    border-radius: 4px;

    &.icon-pencil {
      &::before,
      &::after {
        transition: 0.3s ease-in-out all;
      }

      &::before {
        color: var(--color-main-black);
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 5px;
        left: 5px;
        height: 1px;

        width: 0;
        border-radius: 5px;

        background: var(--color-black);
      }
    }

    &:hover {
      background-color: var(--color-lightgrey-border);
      border-color: transparent;
    }

    &.icon-pencil:hover {
      &::before {
        transform: rotate(-90deg);
        color: var(--color-primary);
      }
      &::after {
        width: 15px;
        background: var(--color-primary);
      }
    }
  }

  &.border {
    border-color: var(--color-primary);

    &:hover {
      background-color: var(--color-white);
    }
  }

  &.rectangle {
    width: 40px;
    height: 30px;
    border-radius: 4px;
    font-size: 8px;
    flex: 0 0 30px;
  }

  &.transparent {
    border-color: transparent;
    background-color: transparent;
    color: var(--color-grey);
    font-size: 8px;
    width: 30px;
    height: 20px;
  }

  &.download {
    overflow: hidden;

    &:hover {
      &:before {
        animation: slideDown 0.5s linear forwards;
      }
    }
  }

  &.arrow-down {
    &:before {
      transition: 0.3s ease-in-out all;
    }

    &:hover {
      &:before {
        transform: translateY(3px);
      }
    }
  }

  &.arrow-up {
    &:before {
      transition: 0.3s ease-in-out all;
    }

    &:hover {
      &:before {
        transform: translateY(-3px);
      }
    }
  }
}

.theme-btn {
  background-color: var(--color-lightgrey);
  padding: 4px;
  border-radius: 7px;
  display: flex;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out all;

  &::before {
    background-color: var(--color-primary);
    content: "";
    top: 4px;
    left: 4px;
    width: 42px;
    height: 32px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    transition: 0.3s ease-in-out all;
  }

  &.dark-active {
    &:hover {
      .dark-btn {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradient 0.3s linear forwards;
        }
      }
    }

    &::before {
      transform: translateX(100%);
    }
  }

  .light-btn,
  .dark-btn {
    position: relative;
    overflow: hidden;

    width: 42px;
    height: 32px;
    border-radius: 5px;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: 0.3s ease-in-out all;

    &::before {
      font-family: "icomoon";
    }
  }

  .light-btn {
    color: #fcfdff;

    &::before {
      content: "\e931";
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: skew(45deg);
    }
  }
  .dark-btn {
    color: #262d41;

    &::before {
      content: "\e932";
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: skew(45deg);
    }
  }

  &:hover:not(.dark-active) {
    .light-btn {
      &::after {
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0.7) 100%
        );
        animation: gradient 0.3s linear forwards;
      }
    }
  }

  @mixin tab {
    padding: 2px;

    &::before {
      width: 32px;
      height: 32px;
      top: 2px;
      left: 2px;
    }

    .light-btn,
    .dark-btn {
      width: 32px;
      height: 32px;
      font-size: 14px;
    }
  }
}

.menu-btn {
  display: none;
  height: 40px;
  width: 40px;

  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: var(--color-lightgrey-border);
  border-radius: 4px;
  cursor: pointer;

  transition: ease-in-out 0.3s;

  span {
    display: block;
    left: 11px;
    position: absolute;

    background-color: var(--color-main-black);
    color: var(--color-black);

    height: 2px;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    &:nth-child(1) {
      top: 12px;
      width: 18px;
    }

    &:nth-child(2) {
      top: 48%;
      width: 12px;
    }

    &:nth-child(3) {
      bottom: 12px;
      width: 18px;
    }
  }

  /*&.active {
      span {
          &:nth-child(1) {
              transform: rotate(45deg);
              top: 48%;
          }

          &:nth-child(2) {
              width: 18px;
              top: 48%;
              transform: rotate(-45deg);
          }

          &:nth-child(3) {
              bottom: 48%;
              opacity: 0;
              transform: rotate(-45deg);
          }
      }
  }*/

  @mixin media 768 {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;

    span {
      height: 1.5px;
      left: 10px;
      &:nth-child(1) {
        top: 11px;
        width: 16px;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
      }

      &:nth-child(3) {
        bottom: 11px;
        width: 16px;
      }
    }
  }

  @mixin mob-xl {
    display: flex;
  }
}
