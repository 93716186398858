.media-gallery {
  .media-main-swiper {
    margin-bottom: 10px;
  }
}

.media-main-swiper {
  .swiper-slide {
    max-height: 265px;
    height: 20vw;
    border-radius: 7px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 7px;
      cursor: pointer;
      user-select: none;
    }
  }

  @mixin tab-sm {
    .swiper-slide {
      height: 220px;
    }
  }
}

.media-sub-swiper {
  padding-bottom: 25px;

  .swiper-slide {
    height: 70px;
    border-radius: 7px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 7px;
      cursor: pointer;
      user-select: none;
    }

    &.swiper-slide-thumb-active {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: calc(100% + 3px);
        border-radius: 35px;
        height: 2px;
        width: 100%;
        background-color: var(--color-primary);
        pointer-events: none;
      }
    }
  }

  .swiper-scrollbar {
    height: 4px;
    background: var(--color-lightgrey-border);
    transition: 0.3s ease-in-out all;
  }

  .swiper-scrollbar-drag {
    background: var(--color-darkgrey-text);
  }
}

.tabs {
  display: grid;
  grid-gap: 10px;

  &.dark-content {
    .tabs__content {
      background-color: var(--color-code);
    }
  }

  &__btn-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 10px;

    &:container(width <= 550px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &:container(width <= 300px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__btn {
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 7px;
    border: var(--border-width) solid var(--color-lightgrey-border);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    transition: 0.3s ease-in-out all;

    &:hover {
      box-shadow: 0px 2px 10px rgba(#fdcc05, 0.4);
      border-color: var(--color-primary);
    }

    span {
      font-weight: 500;
      font-size: 15px;
    }

    .btn {
      &:hover {
        color: var(--color-primary);
      }
    }

    &.active {
      background-color: var(--color-primary);
      border-color: var(--color-primary);

      span {
        color: var(--color-white);
        transition: 0.3s ease-in-out color;
      }

      .btn {
        color: var(--color-white);

        &:hover {
          color: var(--color-main-black);
        }
      }
    }
  }

  &__content-list {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  &__content {
    font-family: var(--font-code);
    font-style: normal;
    font-size: 12px;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 7px;

    border: var(--border-width) solid var(--color-lightgrey-border);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.3s ease-in-out all;
    max-height: 420px;
    overflow-x: auto;
    @mixin scrollbar;

    &.active {
      opacity: 1;
      position: relative;
      pointer-events: all;
    }

    code.hljs {
      padding: 0;
      overflow: initial;
    }

    i,
    p,
    pre {
      width: max-content;
    }

    i {
      font-style: inherit;
      color: var(--color-green);
      display: inline-block;
    }

    p {
      font-size: inherit;

      & + * {
        margin-top: 5px;
      }
    }
  }
}
