.category-banner {
  padding: 12px;
  margin-bottom: 20px;
  background-color: #383838;
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;

  &__info {
    display: flex;
    align-items: center;

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
    }
  }

  &__image {
    border-radius: 7px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    color: #383838;

    background-color: rgba(255, 255, 255, 1);
    border: var(--border-width) solid rgba(255, 255, 255, 0.2);

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }

  &__articles {
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 15px;
    background-color: rgba(80, 80, 80, 1);
    border: var(--border-width) solid rgba(255, 255, 255, 0.2);
    color: #fff;
  }

  &.color-1 {
    background-color: var(--color-1);

    .category-banner__image {
      color: var(--color-1);
    }

    .category-banner__articles {
      background-color: #98d750;
    }
  }

  &.color-2 {
    background-color: var(--color-2);

    .category-banner__image {
      color: var(--color-2);
    }

    .category-banner__articles {
      background-color: #ff6d4c;
    }
  }

  &.color-3 {
    background-color: var(--color-3);

    .category-banner__image {
      color: var(--color-3);
    }

    .category-banner__articles {
      background-color: #4f9dff;
    }
  }

  &.color-4 {
    background-color: var(--color-4);

    .category-banner__image {
      color: var(--color-4);
    }

    .category-banner__articles {
      background-color: #917bff;
    }
  }

  &.color-5 {
    background-color: var(--color-5);

    .category-banner__image {
      color: var(--color-5);
    }

    .category-banner__articles {
      background-color: #ff71a7;
    }
  }

  &.color-6 {
    background-color: var(--color-6);

    .category-banner__image {
      color: var(--color-6);
    }

    .category-banner__articles {
      background-color: #ffb525;
    }
  }

  .media-main-swiper {
    .swiper-slide {
      height: 22vw;
    }
  }

  .tabs {
    &__content {
      font-size: 14px;
    }
  }

  @mixin tab {
    &__info {
      h2 {
        font-size: 18px;
      }
    }

    &__articles {
      font-size: 13px;
    }
  }
}

.post-card {
  background-color: var(--color-white);
  border-radius: 7px;
  box-shadow: 0px 2px 20px rgba(var(--color-black), 0.04);
  padding: 10px 10px 10px 15px;

  transition-property: box-shadow, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  display: grid;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &__descr {
    margin-right: auto;
    width: 100%;

    h1,
    h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 130%;
    }

    h1 {
      transition: ease-in-out 0.3s;

      &:hover {
        color: var(--color-primary);
      }
    }

    p {
      font-size: 15px;
      line-height: 150%;
      color: var(--color-black);
      transition: 0.3s ease-in-out color;

      @include max-line-leng(3);

      & + * {
        margin-top: 15px;
      }
    }
  }

  &__image {
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    align-self: center;

    @mixin aspect-ratio 245, 170;

    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: ease-in-out 0.3s;
    }

    &:hover {
      img {
        transform: scale(1.12);
      }
    }
  }

  &__top-content {
    display: flex;
    justify-content: space-between;
  }

  &.intro {
    padding: 15px 15px 15px 25px;
    display: grid;
    grid-column-gap: 24px;

    .post-card__descr {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1,
      h2 {
        font-weight: 700;
      }
    }

    /* prettier-ignore */
    &:container(width >= 650px) {
      grid-template-columns: calc(100% - 316px - 24px) 316px;
    }

    &:container(520px <= width < 650px) {
      grid-template-columns: calc(100% - 260px - 24px) 260px;
    }

    &:container(width < 520px) {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      padding: 15px;

      .post-card__descr {
        grid-row: 2;
      }

      post-card__image {
        grid-row: 1;
      }
    }

    &:container(width < 320px) {
      padding: 10px;
    }
  }

  &.module-card {
    padding: 10px;
    flex-direction: column;

    .post-card__descr {
      h1,
      h2 {
        font-size: 18px;
      }
    }

    .post-card__image {
      margin-left: 0;
      margin-right: 10px;
      width: 40%;
      height: 7.7vw;
      max-height: 120px;
      max-width: 150px;
      align-self: flex-start;
    }

    .author {
      padding: 0;
      border: none;
      margin-right: 0;
      flex-direction: row;

      &__image-colored {
        margin-bottom: 0;
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        padding: 2px;
        margin-right: 10px;
      }
    }

    .tag-list {
      margin-bottom: 0;
    }
  }

  &.module-card-stretched {
    display: grid;
    padding: 10px;

    @mixin media 451 {
    }

    @mixin media 376 {
    }

    .post-card__descr {
      margin-right: 0;
      flex: 1;

      h1,
      h2 {
        font-size: 20px;
        font-weight: 400;
        display: -webkit-box;
        text-overflow: ellipsis;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
        word-break: break-all;

        @include media(1200) {
          font-size: 18px;
        }

        @include media(1025) {
          font-size: 16px;
          font-weight: 600;
        }

        @include media(451) {
          display: block;
          word-break: initial;
          overflow: initial;
          line-clamp: initial;
          line-height: 130%;
        }
      }

      h1 {
        transition: ease-in-out 0.3s;
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    .post-card__image {
      @mixin aspect-ratio 16, 9;

      img {
        transition: ease-in-out 0.3s;
      }

      &:hover img {
        transform: scale(1.12);
      }
    }

    .author {
      padding: 0;
      border: none;
      margin-right: 0;
      flex-direction: row;
      margin-top: 10px;

      &__link {
        display: flex;
        align-items: center;

        &:hover {
          .author__image-colored {
            padding: 0;

            img {
              transform: scale(1.15);
            }
          }
        }
      }

      &__image-colored {
        margin-right: 10px;
        margin-bottom: 0;
        width: 36px;
        height: 36px;
        flex: 0 0 36px;
        padding: 4px;
      }
    }
  }

  .author {
    padding-right: 15px;
    border-right: var(--border-width) solid var(--color-grey);

    display: flex;
    flex-direction: column;
    align-items: center;

    &__image-colored {
      margin-bottom: auto;
    }

    &__rate {
      font-weight: 500;
      font-size: 15px;
    }

    &__comments {
      margin-top: 10px;
      font-size: 15px;
    }

    .btn {
      background-color: transparent;
      &.active {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }

      &:hover {
        background-color: var(--color-lightgrey-border);
        color: var(--color-primary);
        border-color: var(--color-primary);
      }

      &.rectangle {
        &.active {
          border-color: transparent;

          & + .author__rate {
            color: var(--color-primary);
          }
        }

        &:hover {
          border-color: transparent;

          & + .author__rate {
            color: var(--color-primary);
          }
        }
      }
    }

    @mixin tab-sm {
      padding-right: 10px;
    }
  }

  .tag-list {
    margin: 10px 0;

    display: flex;
    flex-wrap: nowrap;

    position: relative;
    overflow: hidden;
    -webkit-mask-image: -webkit-gradient(
      linear,
      90% 100%,
      100% bottom,
      from(#000),
      to(transparent)
    );

    &.disabled {
      display: none;
    }

    li {
      margin-right: 5px;
      margin-bottom: 0;
    }

    &.hovered {
      .tag {
        border: var(--border-width) solid transparent;
        transition: 0.3s ease-in-out all;

        &:hover {
          border-color: var(--color-primary);
        }
      }
    }
  }

  &:not(.intro, .module-card-stretched) {
    &:container(width < 380px) {
      grid-template-columns: 65px minmax(0, calc(100% - 80px));
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      padding: 10px;

      .author {
        grid-row: 2 / 3;
      }
      .post-card {
        &__descr {
          grid-column: 2 / -1;
          grid-row: 2 / 3;
        }
        &__image {
          grid-column: 1 / -1;
          grid-row: 1;
        }
      }
    }

    &:container(380px <= width < 480px) {
      grid-template-columns: 65px minmax(0, calc(100% - 285px)) 190px;
      grid-column-gap: 15px;
      grid-row-gap: 15px;

      .author {
        grid-row: 1 / 3;
      }
      .post-card {
        &__descr {
          grid-column: 2 / -1;
        }
        &__image {
          grid-column: 2 / -1;
          grid-row: 1;
        }
      }
    }

    &:container(480px <= width < 640px) {
      grid-template-columns: 65px minmax(0, calc(100% - 285px)) 190px;
      grid-column-gap: 15px;
    }

    /* prettier-ignore */
    &:container(width >= 640px) {
            grid-template-columns: 65px minmax(0, calc(100% - 245px - 95px)) 245px;
            grid-column-gap: 15px;
        }
  }

  &.module-card-stretched {
    &:container(width < 320px) {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      padding: 10px;
    }

    &:container(320px <= width < 440px) {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      padding: 15px;
    }

    &:container(440px <= width < 620px) {
      grid-template-columns: 220px calc(100% - 220px - 20px);
      grid-column-gap: 20px;
    }

    /* prettier-ignore */
    &:container(width >= 620px) {
      grid-template-columns: 245px calc(100% - 245px - 20px);
      grid-column-gap: 20px;
    }
  }
}
